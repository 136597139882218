import { message } from 'antd';
import type { BaseData, PaginationData } from 'egenie-common';
import { multipartUpload, renderModal, request } from 'egenie-common';
import { BatchReport } from 'egenie-utils';
import * as imageConversion from 'image-conversion';
import { action, computed, observable, set, toJS } from 'mobx';
import { nanoid } from 'nanoid';
import React from 'react';
import { arrayMove } from 'react-sortable-hoc';
import { api } from './api';
import { CreateFolderStore } from './createFolder';
import type { IImgInfo, IList, ISelectFile, IVideoInfo, IVideoLimit, TTabId, TType } from './interface';

interface CascaderOptionType {
  value?: string | number;
  label?: React.ReactNode;
  disabled?: boolean;
  isLeaf?: boolean;
  loading?: boolean;
  children?: CascaderOptionType[];

  [key: string]: any;
}

// 允许上传的格式
const imgFormatList = [
  'jpeg',
  'jpg',
  'png',
];

export class UploadStore {
  constructor(options?: Partial<UploadStore>) {
    this.createFolderStore = new CreateFolderStore({ parent: this });
    set(this, { ...(options || {}) });
  }

  @observable public createFolderStore: CreateFolderStore;

  @observable public type: TType = 'picManage'; // picManage 图片 videoManage 视频

  @observable public moduleName = {
    picManage: '图片',
    videoManage: '视频',
  };

  @observable public manageURL = {
    picManage: {
      url: '/egenie-vendor-pos/pictureAndVideoManager?type=picture',
      id: '2308',
    },
    videoManage: {
      url: '/egenie-vendor-pos/pictureAndVideoManager?type=video',
      id: '2308',
    },
  };

  @observable public uploadTab = {
    picManage: {
      icon: 'icon-tp',
      desc: '仅支持 jpg、png、jpg 格式图片上传',
    },
    videoManage: {
      icon: 'icon-sp',
      desc: '仅支持大小50m以内格式为MP4的文件上传',
    },
  };

  @observable public tabId: TTabId = 'upload'; // manage 管家  upload 本地上传

  @observable public catalogueList: CascaderOptionType[] = []; // 目录list

  @observable public uploadCatalogueId = []; // 上传tab目录值

  @observable public manageCatalogueId = []; // 管家tab目录值

  @observable public searchKey = ''; // 搜索框的值

  @observable public loading = false; // 压缩以及校验空间大小loading状态

  @observable public uploading = false; // 是否正在上传

  @observable public imgSize;// 上传图片限制尺寸

  @observable public videoSize: IVideoLimit;// 上传视频限制条件

  @observable public limitSize;// 上传图片限制大小

  @observable public current = 1; // 分页当前页

  @observable public total = 0; // 分页总数据

  @observable public pageSize = 30; // 分页总数据

  @observable public list = [];

  @observable public choosedList: ISelectFile[] = [];

  @observable public folderData = [];

  @observable public selectFileList = []; // 选择的文件列表

  @observable public multiple = true; // 是否允许多选,默认false

  @observable public limitNumber = 9999; // 限制选择数量 1 - 无穷大

  @observable public onlyUpload = false; // 只上传

  @observable public onlySelect = false; // 只选择

  @observable public showCancelButton = true; // 只选择

  @observable public activeTab = 'manage'; // 只选择

  @observable public tempManageCatalogueId = []; // 暂存管家里选择id顺序，用于回写目录

  public queryFlag = false; // 此标记用于解决ant的bug, pageSizeChange的时候也会调用pageChange

  public successUploadCount = 0;

  // eslint-disable-next-line @typescript-eslint/ban-types
  @observable public callBack: (params: ISelectFile[]) => void; // 弹窗确定回调

  // eslint-disable-next-line @typescript-eslint/ban-types
  @observable public cancelCallBack: () => void; // 取消弹窗回调

  @observable public visible = false; // 弹窗回避回调

  @computed
  public get getQueryParams() {
    return {
      page: this.current,
      pageSize: this.pageSize,
      parentId: this.manageCatalogueId[this.manageCatalogueId.length - 1] || 0,
      searchKey: this.searchKey,
      sidx: 'create_time',
      sord: 'desc',
    };
  }

  @computed
  public get allowSelect() {
    if (this.choosedList.length >= this.limitNumber) {
      // message.info(`最多只能选择${this.limitNumber}个${this.moduleName[this.type]}!`);
      return false;
    }
    return true;
  }

  // tab切换
  @action public onChangeTabId = (tabId: TTabId): void => {
    this.activeTab = tabId;
    if (tabId === 'manage' && !this.list.length) {
      this.onQueryData();
    }
  };

  // 弹窗确定
  @action public onConfirmSelect = async() => {
    const selectedItem = this.choosedList.filter((el) => el.type === 'url');

    // 视频的话校验尺寸 时长等信息
    if (this.type === 'videoManage') {
      for (let i = 0; i < selectedItem.length; i++) {
        const { id } = selectedItem[i];
        const res = await request<BaseData<IVideoInfo>>({ url: `/api/goodsPic/rest/videoManage/getById?id=${id}` });
        const {
          size,
          duration,
        } = res.data;

        let width,
          height;
        if (size) {
          const sizeObj = JSON.parse(size);
          width = sizeObj.width;
          height = sizeObj.height;
        }

        console.log(size, duration, width, height);

        // 校验时长是否存在
        if (this.videoSize?.duration && !duration) {
          message.warn('该视频时长信息缺失，请重新选择');
          return;
        }

        // 校验时长
        if (this.videoSize?.duration && duration > this.videoSize.duration) {
          message.warn(`请选择时长小于${this.videoSize.duration}s的视频`);
          return;
        }

        // 校验尺寸信息是否存在(如果只有最小宽度限制 只校验宽度)
        if (this.videoSize?.minWidth && !width) {
          message.warn('该视频尺寸信息缺失，请重新选择');
          return;
        }

        // 校验尺寸信息是否存在(如果有比例限制 长宽都要校验)
        if (this.videoSize?.ratio && (!width || !height)) {
          message.warn('该视频尺寸信息缺失，请重新选择');
          return;
        }

        // 校验尺寸(最小宽度)
        if (this.videoSize?.minWidth && width < this.videoSize.minWidth) {
          message.warn('请选择尺寸合适的视频');
          return;
        }

        // 校验尺寸(比例)
        if (Array.isArray(this.videoSize?.ratio) && this.videoSize?.ratio.length > 0) {
          const validSize = this.videoSize.ratio.some((item) => {
            return width / height === item;
          });
          if (!validSize) {
            message.warn('请选择尺寸合适的视频');
            return;
          }
        }
      }

      this.callBack && this.callBack(selectedItem);
      this.visible = false;
      return;
    }

    // 校验大小和尺寸
    this.uploading = true;// 将确定按钮置为loading状态
    for (let i = 0; i < selectedItem.length; i++) {
      const { url } = selectedItem[i];
      const imgInfo: IImgInfo = await request({
        url: this.dealImgUrl(url),
        withCredentials: false,
      });
      if (!imgInfo.ImageWidth) {
        imgInfo.ImageHeight = { value: imgInfo?.height.toString() };
        imgInfo.ImageWidth = { value: imgInfo?.width.toString() };
        imgInfo.Format = { value: imgInfo?.format };
        imgInfo.FileSize = { value: imgInfo?.size.toString() };
      }
      console.log('getImageInfo', imgInfo);

      // 尺寸处理
      if (this.imgSize) {
        const {
          ImageHeight,
          ImageWidth,
          FileSize,
        } = imgInfo;

        const {
          ratio,
          width,
          height,
          minWidth,
          minHeight,
          maxWidth,
          maxHeight,
          maxSize,
        } = this.imgSize;

        // 确定缩放的宽高width height
        let resizeWidth: number;
        let resizeHeight: number;

        // 明确了宽高的直接赋值（大多数情况）
        if (width && height) {
          resizeWidth = width;
          resizeHeight = height;
        }

        // 比最小宽度还小取最小宽度
        resizeWidth = (minWidth && ImageWidth.value < minWidth) ? minWidth : (resizeWidth || ImageWidth.value);

        // 比最大宽度还大取最大宽度
        resizeWidth = (maxWidth && ImageWidth.value > maxWidth) ? maxWidth : (resizeWidth || ImageWidth.value);

        // 比最小高度还小取最小高度
        resizeHeight = (minHeight && ImageHeight.value < minHeight) ? minHeight : (resizeHeight || ImageHeight.value);

        // 比最大高度还大取最大高度
        resizeHeight = (maxHeight && ImageHeight.value > maxHeight) ? maxHeight : (resizeHeight || ImageHeight.value);

        // 比例为1
        if (ratio === 1 && resizeWidth !== resizeHeight) {
          const longer = Math.max(resizeWidth, resizeHeight);
          resizeWidth = longer;
          resizeHeight = longer;
        }

        // 比例为1:1或3:4(拼多多主图)
        if (Array.isArray(ratio) && resizeWidth !== resizeHeight && (resizeWidth / resizeHeight) !== ratio[1]) {
          // 如果宽高比小于3:4按照3:4来缩放 否则1:1
          if ((resizeWidth / resizeHeight) < 0.75) {
            resizeWidth = resizeHeight * 0.75;
          } else {
            const longer = Math.max(resizeWidth, resizeHeight);
            resizeHeight = longer;
            resizeWidth = longer;
          }
        }

        let newUrl = '';

        // 需要缩放的加前缀/不需要则还是使用原始url
        resizeWidth = Number(resizeWidth);
        resizeHeight = Number(resizeHeight);
        if (resizeWidth !== Number(ImageWidth.value) || resizeHeight !== Number(ImageHeight.value)) {
          newUrl = `${new URL(url).origin}${new URL(url).pathname}?x-oss-process=image/resize,m_pad,w_${resizeWidth},h_${resizeHeight},color_FFFFFF`;
        } else {
          newUrl = url;
        }

        selectedItem[i].url = newUrl;
      }

      // 格式处理
      const { Format } = imgInfo;
      if (!imgFormatList.includes(Format.value)) {
        // 如果处理过尺寸直接加格式
        if (selectedItem[i].url.includes('x-oss-process=image')) {
          selectedItem[i].url = `${selectedItem[i].url}/format,jpg`;
        } else {
          // 没有处理过直接对原图进行处理
          selectedItem[i].url = `${new URL(url).origin}${new URL(url).pathname}?x-oss-process=image/format,jpg`;
        }
      }

      if (this.limitSize) {
        // 文件大小处理
        const { FileSize } = imgInfo;
        if (Number(FileSize.value) / 1024 / 1024 > this.limitSize) {
          // 图片大小超过限制 需处理
          // 如果已经处理过图片
          if (selectedItem[i].url.includes('x-oss-process=image')) {
            // 处理过格式的直接加质量处理
            if (Format.value === 'jpg' || selectedItem[i].url.includes('jpg')) {
              selectedItem[i].url = `${selectedItem[i].url}/quality,q_80`;
            } else {
              selectedItem[i].url = `${selectedItem[i].url}/format,jpg/quality,q_80`;
            }
          } else {
            // 未处理过处理格式和质量
            selectedItem[i].url = `${new URL(url).origin}${new URL(url).pathname}?x-oss-process=image/format,jpg/quality,q_80`;
          }
        }
      }
    }

    this.uploading = false;

    this.callBack && this.callBack(selectedItem);
    this.visible = false;
  };

  // 处理图片url地址
  private dealImgUrl = (url: string) => {
    const urlParse = new URL(url);
    const res = `${urlParse.origin}${urlParse.pathname}?x-oss-process=image/info`;
    return res;
  };

  // 弹窗关闭
  @action public onCancel = (): void => {
    this.visible = false;
    this.resetAll();
    this.cancelCallBack && this.cancelCallBack();

    // TODO: 重置
  };

  // 弹窗开启
  @action public onOpen = (): void => {
    // TODO: 发布时要去掉构造函数里的请求
    this.getFolder();
    this.resetAll();
    this.visible = true;
    console.log(this.onlySelect, 'this.onlySelect');
    if (this.onlySelect) {
      this.activeTab = 'manage';
    }
    if (this.onlyUpload) {
      this.activeTab = 'upload';
    }
  };

  // 重置所有
  @action public resetAll = (): void => {
    // TODO: 待与产品经理确认需要重置什么，不建议重置已请求的数据，因为图片的视频要重新渲染。
    console.log('重置');
    this.resetInputValue();
    this.choosedList = [];
    this.list = this.list.map((el) => {
      el.isChoose = false;
      return { ...el };
    });
  };

  // 跳转相应管家
  @action public onJumpToManage = (): void => {
    const {
      id,
      url,
    } = this.manageURL[this.type];
    window.top.egenie.openTab(
      url,
      id,
      this.moduleName[this.type]
    );
  };

  // 删除已选择
  @action public onDeleteChoosed = (id: string | number, e): void => {
    console.log(id, '删除id');
    this.choosedList.splice(this.choosedList.findIndex((v) => v.id === id), 1);
    const item = this.list.find((v) => v.id === id);
    item && (item.isChoose = false);
  };

  @action public onSortEnd = ({
    oldIndex,
    newIndex,
  }) => {
    console.log(oldIndex, newIndex);
    if (oldIndex !== newIndex) {
      this.choosedList = arrayMove(this.choosedList, oldIndex, newIndex);
    }
  };

  // 搜索图片change
  @action public onSearchPicChange = (e): void => {
    this.searchKey = e.target.value;
  };

  // 搜索图片
  @action public onSearchPic = (e): void => {
    set(this, { current: 1 });
    this.onQueryData();
  };

  // 从图片管家中选择
  @action public onSelectFromPicManage = (id: string | number, fileType): void => {
    // 后端返回的type是1 展示的是文件夹 搜索相关目录
    console.log(id, fileType);
    if (fileType === 1) {
      this.tempManageCatalogueId = [];
      this.syncManageCatalogue(this.catalogueList, id);
      this.manageCatalogueId = this.tempManageCatalogueId;
      this.onQueryData({ parentId: id });
      return;
    }
    const item = this.list.find((v) => v.id === id);
    const existed = this.choosedList.find((v) => v.id === id);
    if (existed) {
      return;
    }
    if (!this.allowSelect) {
      return;
    }
    item.isChoose = true;
    this.choosedList.push({
      id,
      type: 'url',
      url: item.url,
      name: item.url,
    });
  };

  @action public syncManageCatalogue = (catalogueList, id) => {
    catalogueList.forEach((el) => {
      // 判断是否是父节点
      if (el.key === id && !el.parentId) {
        this.tempManageCatalogueId.unshift(id);
        return;
      }

      // 找到后逆向 向上找
      if (el.key === id && el.parentId) {
        this.tempManageCatalogueId.unshift(id);
        this.syncManageCatalogue(this.catalogueList, el.parentId);
        return;
      }

      // 继续找下层
      if (el.children.length > 0) {
        this.syncManageCatalogue(el.children, id);
      }
    });
  };

  // 目录选择
  @action public onCatalogueChange = (catalogueType, value): void => {
    this[catalogueType] = value;
    if (catalogueType === 'manageCatalogueId') {
      set(this, { current: 1 });
      this.onQueryData();
    }
  };

  // 管家和已选择的图片 相同部分在管家里要勾选
  @action public rewriteCheck = (): void => {
    const selectedIds = this.choosedList.map((v) => String(v.id));
    const listIds = this.list.reduce((pre, cur) => {
      return {
        ...pre,
        [cur.id]: cur,
      };
    }, {});
    console.log(toJS(selectedIds), toJS(listIds));
    selectedIds.forEach((el) => {
      const item = listIds[el];
      if (item) {
        item.isChoose = true;
      }
    });
  };

  // 点击上传
  @action public onSelectFile = () => {
    if (!this.uploadCatalogueId.length) {
      message.info('请选择目录！');
      return;
    }
    if (!this.allowSelect) {
      return;
    }

    document.getElementById('multipleSelectFile')
      .click();
  };

  // 选择文件change事件
  @action public onChangeUploadChange = (e) => {
    console.log(e.target.files, 'handleTestChange');
    const files = e.target.files;
    const fileList = [...e.target.files];
    if (fileList.length) {
      // 强制给选择的文件赋值id, 如果上传成功后会替换
      fileList.forEach((el) => {
        el.id = nanoid(5);
      });
      console.log(fileList, 'fileList');
      this.selectFileList = fileList;

      this.onStartUpload();
    }
  };

  public regFileType = {
    picManage: /image\/(jpeg|png|gif)+$/,
    videoManage: /video\/(mp4)+$/,
  };

  // 点击上传文件
  @action public onStartUpload = async() => {
    this.loading = true;
    this.successUploadCount = 0;
    const {
      uploadCatalogueId,
      selectFileList,
    } = this;
    const verifyFileType = selectFileList.every((el) => this.regFileType[this.type].test(el.type));
    if (!verifyFileType) {
      this.loading = false;
      return message.error(`请选择支持的${this.moduleName[this.type]}格式！`);
    }

    if (!selectFileList.length) {
      return message.info('请选择文件！');
    }

    const compressFileList = [];
    try {
      for (let i = 0; i < selectFileList.length; i++) {
        const {
          name,
          id,
        } = selectFileList[i];
        const compressFile = await this.compressImgOrVideo(selectFileList[i]);
        if (!compressFile) {
          this.loading = false;
          message.error('检验文件失败！');
          return;
        }

        // 校验文件大小
        // @ts-ignore
        if (compressFile.size > this.limitSize * 1024 * 1024) {
          message.error(`不能上传超过${this.limitSize}M的文件`);
          this.loading = false;
          return;
        }

        // 保留文件名，压缩后可能会成为blob,将会丢失文件名
        compressFileList.push({
          blob: compressFile,
          name,
          id,
        });
      }
      const params = compressFileList.map((v) => ({
        capacity: v.blob.size / 1024,
        name: v.name,
        parentId: uploadCatalogueId[uploadCatalogueId.length - 1],
      }));

      const postData = {
        params,
        type: this.type === 'picManage' ? 1 : 2,
        obsConfigId: 3,
      };
      const beforeUploadCb = (file) => {
        this.loading = false;
        const { id, name, blob } = file;
        this.choosedList.push({
          id,
          type: 'loading',
          name,
          blob,
          checkpoint: null,
          progress: 0,
        });
      };
      const completeUploadCb = async(url, file) => {
        const item = this.choosedList.find((v) => v.id === file.id);
        const itemIndex = this.choosedList.findIndex((v) => v.id === file.id);
        item.type = 'url';
        item.url = url;
        const data = {
          capacity: file.blob.size / 1024,
          name: file.name,
          parentId: this.uploadCatalogueId[this.uploadCatalogueId.length - 1],
          url,
        };

        // 获取视频的尺寸和时长
        if (this.type === 'videoManage') {
          try {
            const {
              size,
              duration,
            } = await this.getVideInfo(file);
            Object.assign(data, {
              format: file.blob.type,
              size: JSON.stringify(size),
              duration,
            });
          } catch (e) {
            console.log('加载视频出错', e);
          }
        }
        try {
          const picItem = await request<BaseData<any>>({
            url: api[this.type].upload,
            method: 'post',
            data,
          });

          // FIXME: 这里重置后端返回的id,可能会出错
          if (picItem && picItem.data) {
            item.id = String(picItem.data);
            this.choosedList.splice(itemIndex, 1, toJS(item));
          }
        } catch (error) {
          this.resetInputValue();
          item.type = 'error';
          console.log('上传出错', error);
        }
      };
      const progressCb = (p, file) => {
        const item = this.choosedList.find((v) => v.id === file.id);
        const itemIndex = this.choosedList.findIndex((v) => v.id === file.id);
        item.progress = p;

        // FIXME: 解决进度条不刷新的问题
        this.choosedList.splice(itemIndex, 1, toJS(item));

        console.log(p, 'p, checkpoint');
        if (p === 1) {
          this.successUploadCount++;
        }
      };
      const res = await multipartUpload(postData, compressFileList,
        {
          validatedCb: this.renderBatchReport,
          beforeUploadCb,
          completeUploadCb,
          progressCb,
        }
      );
      message.success(`本次共上传${this.successUploadCount}个${this.moduleName[this.type]}！`);
      console.log('上传之后', res);
    } catch (error) {
      this.resetInputValue();
      message.error('上传失败！');
      console.log(error);
    } finally {
      this.uploading = false;
      this.loading = false;
      this.selectFileList = [];// 全部进入上传队列后重置已选择
    }
  };

  public resetInputValue = () => {
    const selectDom = document.getElementById('multipleSelectFile');
    selectDom && ((selectDom as HTMLInputElement).value = '');
  };

  // 批量处理报告
  public renderBatchReport = (res): void => {
    renderModal(
      <BatchReport
        {...res}
        columns={[
          {
            title: '文件',
            dataIndex: 'saleOrderNo',
          },
          {
            title: '失败原因',
            dataIndex: 'reason',
          },
        ]}
      />
    );
  };

  // 压缩文件
  @action public compressImgOrVideo = async(file: File | Blob, size?: number): Promise<File | Blob | boolean> => {
    console.log('压缩前', file, file.size / 1024 / 1024);
    const type = file.type;
    const fileSize = file.size / 1024 / 1024;

    // TODO:视频压缩待研究
    if (type === 'video/mp4') {
      if (fileSize > 50) {
        message.error('视频大小不能超过50M！');
        return false;
      }
      return file;
    }
    if (fileSize > 10) {
      message.error('图片大小不能超过10M！');
      return false;
    }

    try {
      const compressFile = await imageConversion.compressAccurately(file, size || 1024);
      console.log('压缩后', compressFile, compressFile.size);
      return compressFile;
    } catch (e) {
      console.log('压缩出错', e);
      return false;
    }
  };

  // 获取视频文件的时长和尺寸
  private getVideInfo = (compressFile): Promise<{ duration; size; }> => {
    return new Promise((resolve, reject) => {
      const fileUrl = URL.createObjectURL(compressFile.blob);
      const video = document.createElement('video');
      video.addEventListener('loadeddata', () => {
        const result = {
          duration: video.duration,
          size: {
            width: video.videoWidth,
            height: video.videoHeight,
          },
        };
        resolve(result);
      });

      video.onerror = function() {
        reject('video 后台加载失败');
      };
      video.src = fileUrl;
    });
  };

  @action public getFolder = async() => {
    const res = await request<BaseData<any>>({ url: api[this.type].getFolderContent });
    const data = res.data.treeData;

    function mapTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        const el = data[i];
        el.value = el.key;
        el.label = el.title;
        el.children && mapTreeData(el.children);
      }
    }

    mapTreeData(data);
    this.catalogueList = data;
    if (!this.uploadCatalogueId.length) {
      this.uploadCatalogueId = [data[0]?.key];
    }
    if (!this.manageCatalogueId.length && !this.onlySelect) {
      this.manageCatalogueId = [data[0]?.key];
      this.onQueryData();
    }
    return data;
  };

  // 查询分页数据async Promise<void>
  @action public onQueryData = (params = {}) => {
    request<PaginationData<IList>>({
      url: api[this.type].queryFilesPage,
      method: 'post',
      data: Object.assign(this.getQueryParams, params),
    })
      .then((v) => {
        const {
          list,
          page,
          pageSize,
          totalCount,
        } = v.data;
        if (list) {
          this.list = list.map((v) => ({
            ...v,
            isChoose: false,
            id: String(v.id),
          }));
        }
        this.current = page || 1;
        this.pageSize = pageSize || 10;
        this.total = totalCount || 0;
        this.rewriteCheck();
      });
  };

  // 分页change
  @action public onPageChange = (page, pageSize) => {
    if (!this.queryFlag) {
      set(this, {
        current: page,
        pageSize,
      });
      this.onQueryData();
    }
  };

  // 分页sizeChange
  @action public onPageSizeChange = (page, pageSize) => {
    set(this, {
      current: 1,
      pageSize,
    });
    this.queryFlag = true;
    setTimeout(() => {
      this.queryFlag = false;
    });
    this.onQueryData();
  };
}
